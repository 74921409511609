import styled from 'styled-components';

export const TitleSection = styled.section`
  margin-bottom: 40px;
`;

export const Main = styled.section`
  margin: 40px 0;
`;

export const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #2f2f28;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: capitalize;
`;

export const ModalTitle = styled.h1`
  margin-top: ${({ offsetTop }) => (offsetTop ? '80px' : '10px')};
  margin-bottom: ${({ offsetBottom }) => (offsetBottom ? '80px' : '10px')};
  color: ${({ color }) => (color ? color : '#fff')};
  text-align: ${({ leftAligned }) => (leftAligned ? 'left' : 'center')};
  font-size: 40px;
`;

export const FeatBlock = styled.div`
  margin-top: 60px;
`;

export const FlexCenter = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  flex-direction: column;
`;

export const SubTitle = styled.p`
  color: #000;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 16px;
`;

export const Overview = styled.div`
  width: 320px;
  align-self: flex-start;
`;

export const Tags = styled.div`
  z-index: 1;
  display: flex;
  align-items: ${({ leftAligned }) => (leftAligned ? 'flex-start' : 'center')};
  justify-content: ${({ leftAligned }) =>
    leftAligned ? 'flex-start' : 'center'};
  padding-bottom: 20px;
`;

export const Tag = styled.span`
  background-color: ${props => props.color};
  color: #fff;
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 4px;
  font-size: 10px;
`;

export const Mockup = styled.img`
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '20px')};
  align-self: center;
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
  border: ${({ bordered }) => (bordered ? '2px solid #0e0e0e' : '0')};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  max-width: 980px;
  margin: 20px auto;
  padding: 0 8px;
`;

export const Background = styled.div`
  background-size: cover;
  min-height: 100vh;
  position: absolute;
  width: 100%;
`;

export const TogglerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  max-width: 250px;
`;

export const Toggler = styled.input`
  display: none;
  + label {
    padding: 2px;
    transition: all 0.2s ease;
    background: #fff;
    border: 2px solid #ededed;
    border-radius: 20px;
    margin: 0 8px;
    outline: 0;
    display: block;
    width: 40px;
    height: 20px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
      transition: all 0.2s ease;
      background: #ededed;
      border-radius: 50%;
      left: 0;
    }
  }
  &:checked + label {
    border-color: ${({ color }) => color || '#7fc6a6'};
    &:after {
      left: 50%;
      background: ${({ color }) => color || '#7fc6a6'};
    }
  }
`;
