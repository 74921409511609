import React from 'react';
import { Modal } from '../components/Modal';
import {
  Tags,
  Tag,
  Background,
  Content,
  ModalTitle,
  Toggler,
  TogglerContainer,
  Mockup,
} from '../components/Styled';
import Grid from 'styled-components-grid';
import behancebefore from '../assets/projects/behancebefore.jpg';
import behanceafter from '../assets/projects/behanceafter.jpg';
import { translations } from '../translations';

export class Behance extends React.Component {
  state = {
    newVersion: true,
    comments: false,
  };

  handleChangeVersion = () => {
    this.setState(prevState => {
      return { newVersion: !prevState.newVersion };
    });
  };

  handleChangeComments = () => {
    this.setState(prevState => {
      return { comments: !prevState.comments };
    });
  };

  render() {
    const { newVersion } = this.state;
    const tr = translations[window.lang];
    return (
      <Modal>
        <Background>
          <Content>
            <ModalTitle>Behance Sign Up Form</ModalTitle>
            <Tags>
              <Tag color="#359252">concept</Tag>
              <Tag color="#5f5f5f">ui / ux</Tag>
            </Tags>
            <Grid
              style={{
                display: 'flex',
                marginTop: '20px',
                justifyContent: 'space-between',
              }}
            >
              <Grid.Unit size={{ desktop: 0.6, mobile: 1 }}>
                {newVersion ? (
                  <Mockup src={behanceafter} alt="behanceafter" width="100%" />
                ) : (
                  <Mockup
                    src={behancebefore}
                    alt="behancebefore"
                    width="100%"
                  />
                )}
              </Grid.Unit>

              <Grid.Unit size={{ desktop: 0.36, mobile: 1 }}>
                <TogglerContainer>
                  {tr.before}
                  <Toggler
                    type="checkbox"
                    id="toggler"
                    defaultChecked={newVersion}
                    onChange={this.handleChangeVersion}
                  />
                  <label for="toggler" />
                  {tr.after}
                </TogglerContainer>
                <p>
                  <strong>{tr.case}</strong>
                </p>
                <p>{tr.behanceCase}</p>
                <p>
                  <strong>{tr.role}</strong>
                </p>
                <p>UI/UX-designer</p>
                <p>
                  <strong>{tr.coreFeatures}</strong>
                </p>
                <ul>
                  <li>{tr.behanceFeat1}</li>
                  <li>{tr.behanceFeat2}</li>
                  <li>{tr.behanceFeat3}</li>
                  <li>{tr.behanceFeat4}</li>
                  <li>{tr.behanceFeat5}</li>
                </ul>
              </Grid.Unit>
            </Grid>
          </Content>
        </Background>
      </Modal>
    );
  }
}
