import React from 'react';
import { Modal } from '../components/Modal';
import {
  Tags,
  Tag,
  Content,
  ModalTitle,
  FeatBlock,
  Mockup,
  FlexCenter,
} from '../components/Styled';
import Grid from 'styled-components-grid';
import { Tools } from '../components/Tools';
import tornadoback1 from '../assets/projects/tornadoback1.jpg';
import tormadologo from '../assets/projects/tormadologo.png';
import tornadologofinal from '../assets/projects/tornadologofinal.png';
import tornadomock from '../assets/projects/tornadomock.jpg';
import tornadotriangle from '../assets/projects/tornadotriangle.png';
import tornadonav from '../assets/projects/tornadonav.png';
import tornadoplacebet from '../assets/projects/tornadoplacebet.png';
import tornadoexplore from '../assets/projects/tornadoexplore.png';
import tornadomenu from '../assets/projects/tornadomenu.png';
import tornadomenu2 from '../assets/projects/tornadomenu2.png';
import tornadoresponsive from '../assets/projects/tornadoresponsive.png';
import { translations } from '../translations';
import styled from 'styled-components';

export class Tornadobet extends React.Component {
  render() {
    const tr = translations[window.lang];
    return (
      <Modal background="#080f1c" color="#fff">
        <Background />
        <BackgroundTriangle1 src={tornadotriangle} />
        <BackgroundTriangle2 src={tornadotriangle} />
        <BackgroundTriangle3 src={tornadotriangle} />
        <Content>
          <FlexCenter>
            <ModalTitle offsetTop>Tornadobet</ModalTitle>
            <Tags>
              <Tag color="#5f5f5f">ui / ux</Tag>
              <Tag color="#ed6793">styling</Tag>
              <Tag color="#0abeda">react</Tag>
            </Tags>
            <ShortText>{tr.tornadobetCase}</ShortText>
          </FlexCenter>
          <FeatBlock />
          <Mockup bordered src={tornadomock} alt="tornadobet" width="100%" />

          <FeatBlock>
            <FlexCenter>
              <SubHead>{tr.colors}</SubHead>
              <ShortText>{tr.tornadobetFeat1}</ShortText>
              <ColorsContainer>
                <ColorContainer fontFamily="Ubuntu, sans-serif">
                  <FontComponent>aA</FontComponent>
                  <ColorTitle>Ubuntu Regular</ColorTitle>
                </ColorContainer>
                <ColorContainer>
                  <Color color="#ce0000" />
                  <ColorTitle>{tr.guardsManRed}</ColorTitle>
                </ColorContainer>
                <ColorContainer>
                  <Color color="#F8F8FF" />
                  <ColorTitle>{tr.ghostWhite}</ColorTitle>
                </ColorContainer>
                <ColorContainer>
                  <Color color="#042e4c" />
                  <ColorTitle>{tr.blueWhale}</ColorTitle>
                </ColorContainer>
                <ColorContainer>
                  <Color color="#061826" />
                  <ColorTitle>{tr.midnight}</ColorTitle>
                </ColorContainer>
              </ColorsContainer>
            </FlexCenter>
          </FeatBlock>

          <FeatBlock>
            <FlexCenter>
              <SubHead>{tr.logo}</SubHead>
              <ShortText>{tr.tornadobetFeat2}</ShortText>
              <p>
                <img
                  src={tornadologofinal}
                  alt="tornadologofinal"
                  width="300px"
                />
              </p>
              <p>
                <img
                  style={{ opacity: '.2' }}
                  src={tormadologo}
                  alt="tormadologo"
                  width="160px"
                />
              </p>
            </FlexCenter>
          </FeatBlock>

          <FullWidth />
        </Content>
        <Content>
          <FeatBlock>
            <FeatBlock>
              <ModalTitle>UI Elements</ModalTitle>
            </FeatBlock>
            <FlexCenter>
              <SubHead offsetBottom>{tr.concept}</SubHead>
              <FlexRow>
                <FlexItem>
                  <img src={tornadonav} alt="tornadonav" />
                  <p>Navigate</p>
                </FlexItem>
                <FlexItem>
                  <img src={tornadoexplore} alt="tornadoexplore" />
                  <p>Explore</p>
                </FlexItem>
                <FlexItem>
                  <img src={tornadoplacebet} alt="tornadoplacebet" />
                  <p>Place bet</p>
                </FlexItem>
              </FlexRow>
            </FlexCenter>
          </FeatBlock>
          <FeatBlock>
            <FlexCenter>
              <SubHead>{tr.leftMenu}</SubHead>
              <ShortText>{tr.tornadobetFeat4}</ShortText>
              <Grid style={{ maxWidth: '480px', marginTop: '20px' }}>
                <Grid.Unit size={{ desktop: 0.5, mobile: 1 }}>
                  <img src={tornadomenu} alt="tornadomenu" width="220px" />
                </Grid.Unit>
                <Grid.Unit size={{ desktop: 0.5, mobile: 1 }}>
                  <img src={tornadomenu2} alt="tornadomenu2" width="220px" />
                </Grid.Unit>
              </Grid>
            </FlexCenter>
          </FeatBlock>
          {/* <FeatBlock>
            <FlexCenter>
              <SubHead>{tr.promotions}</SubHead>
              <Grid style={{ maxWidth: '480px', marginTop: '20px' }}>
                <Grid.Unit size={{ desktop: 0.5, mobile: 1 }}>
                  <Mockup src={tornadopromo} alt="tornadopromo" width="220px" />
                </Grid.Unit>
                <Grid.Unit size={{ desktop: 0.5, mobile: 1 }}>
                  <ShortText>{tr.tornadobetFeat5}</ShortText>
                </Grid.Unit>
              </Grid>
            </FlexCenter>
          </FeatBlock> */}
          <FeatBlock>
            <FlexCenter>
              <SubHead>{tr.outcomes}</SubHead>
              <ShortText>{tr.tornadobetFeat3}</ShortText>
              <Outcomes>
                <Outcome border="rgba(255,255,255,.3)">
                  <Price>1.24</Price>
                </Outcome>
                <Outcome border="#ce0000">
                  <Price>1.24</Price>
                </Outcome>
                <Outcome border="#fff" background="#1E2A43">
                  <Price>1.24</Price>
                </Outcome>
                <Outcome
                  border="rgba(255,255,255,.3)"
                  style={{ borderTop: '2px solid #108824' }}
                >
                  <Arrow color="#108824">▲</Arrow>
                  <Price>1.31</Price>
                </Outcome>
                <Outcome
                  border="rgba(255,255,255,.3)"
                  style={{ borderBottom: '2px solid #881010' }}
                >
                  <Arrow color="#881010">▼</Arrow>
                  <Price>1.11</Price>
                </Outcome>
                <Outcome border="rgba(255,255,255,.3)" style={{ opacity: 0.3 }}>
                  <Price>–</Price>
                </Outcome>
              </Outcomes>
            </FlexCenter>
          </FeatBlock>
          <FeatBlock>
            <img src={tornadoresponsive} alt={tornadoresponsive} width="100%" />
            </FeatBlock>
          {/* <p>
            <strong>{tr.role}</strong>
          </p>
          <p>UI/UX Designer, Product Designer, Frontend-developer</p>
          <p>
            <strong>{tr.coreFeatures}</strong>
          </p>
          <ul>
            <li>{tr.tornadobetFeat1}</li>
            <li>{tr.tornadobetFeat2}</li>
            <li>{tr.tornadobetFeat3}</li>
            <li>{tr.tornadobetFeat4}</li>
            <li>{tr.tornadobetFeat5}</li>
          </ul> */}
        </Content>

        <Tools toolsList={['AffinityDesigner', 'Framer', 'ReactJS', 'Redux', 'GutHub', 'StyledComponents', 'Confluence']} />
      </Modal>
    );
  }
}

const Background = styled.div`
  background: url('${tornadoback1}') repeat center center; 
  position: fixed;
  background-size: cover;
  width: 100%;
  right: 0;
  top: 0;
  height: 100%;
  z-index: -1;
`;

const BackgroundTriangle1 = styled.img`
  z-index: -1;
  position: absolute;
  width: 40vw;
  right: 0vw;
  top: -10vh;
  opacity: 0.05;
`;

const BackgroundTriangle2 = styled.img`
  position: absolute;
  z-index: -1;
  width: 30vw;
  left: 20vw;
  top: 10vh;
  transform: rotate(20deg);
  opacity: 0.05;
`;

const BackgroundTriangle3 = styled.img`
  position: absolute;
  z-index: -1;
  width: 50vw;
  left: -30vw;
  top: 30vh;
  transform: rotate(30deg);
  opacity: 0.05;
`;

const SubHead = styled.p`
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  margin-bottom: ${({ offsetBottom }) => (offsetBottom ? '20px' : 0)};
`;

const Color = styled.div`
  background: ${({ color }) => color};
  width: 60px;
  height: 60px;
  max-width: 14vw;
  max-height: 14vw;
  border-radius: 50%;
  margin-bottom: 5px;
`;

const ColorTitle = styled.p`
  font-size: 9px;
  line-height: 12px;
  opacity: 0.5;
`;

const Outcomes = styled.div`
  margin-top: 20px;
`;

const ColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  text-align: center;
  margin-right: 1vw;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : 'inherit')};
`;

const ColorsContainer = styled.div`
  display: flex;
  margin-top: 40px;
`;

const FullWidth = styled.div`
  width: 100%;
  padding: 0 0;
`;
const FontComponent = styled.div`
  width: 60px;
  max-width: 14vw;
  max-height: 14vw;
  height: 60px;
  background: #ccc;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
  font-size: 34px;
`;

const ShortText = styled.p`
  max-width: 500px;
`;

const Outcome = styled.div`
  border: 2px solid ${({ border }) => border};
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  width: 72px;
  font-family: 'Ubutntu', sans-serif;
  margin-right: 1vw;
  background: ${({ background }) => (background ? background : 'none')};
`;

const Price = styled.span`
  font-weight: bold;
`;

const Arrow = styled.span`
  padding-right: 10px;
  line-height: 14px;
  font-size: 10px;
  color: ${({ color }) => color};
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FlexItem = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 1vw;
`;
