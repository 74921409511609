import React from 'react';
import { withRouter, Switch, Route, NavLink } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
// import logo from './logo.svg';
import bkslogo from './assets/logos/bkslogo.png';
import universallogo from './assets/logos/universallogo.png';
import quantumlogo from './assets/logos/quantumlogo.png';
import oglogo from './assets/logos/oglogo.png';
import kespalogo from './assets/logos/kespalogo.png';
import habrlogo from './assets/logos/habrlogo.png';
import corwelllogo from './assets/logos/corwelllogo.png';
import { Work } from './pages/Work';
import { Info } from './pages/Info';
import { Yamusic } from './projects/Yamusic';
import { Kespa } from './projects/Kespa';
import { Tornadobet } from './projects/Tornadobet';
import { Rastenika } from './projects/Rastenika';
import { Bks } from './projects/Bks';
import { SportCoupon } from './projects/SportCoupon';
import { Behance } from './projects/Behance';
import { NotFound } from './pages/NotFound';
import { translations } from './translations';

const userLang = navigator.language || navigator.userLanguage;
if (userLang === 'ru') {
  window.lang = 'ru';
} else {
  window.lang = 'en';
}

class AppComponent extends React.Component {
  state = {
    lang: 'en',
  };

  changeLang = newLang => {
    window.lang = newLang;
    this.setState({
      lang: newLang,
    });
  };

  render() {
    const { location } = this.props;
    const { lang } = window;
    return (
      <React.Fragment>
        <Header>
          {/* <Logo href="/">
              <img height="100%" width="auto" src={logo} alt="inzeppelin" />
            // </Logo> */}
          <nav>
            <NavItem exact to="/">
              {translations[lang].work}
            </NavItem>
            <NavItem to="/info">{translations[lang].info}</NavItem>
            <NavItem to="/resume.pdf" target="_blank">
              {translations[lang].resume}
              .pdf
            </NavItem>
          </nav>
          <Languages>
            <Language
              active={lang === 'ru'}
              onClick={() => this.changeLang('ru')}
            >
              ru
            </Language>
            |
            <Language
              active={lang === 'en'}
              onClick={() => this.changeLang('en')}
            >
              en
            </Language>
          </Languages>
        </Header>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="zoom" timeout={300}>
            <Switch location={location}>
              <Route exact path="/yamusic" component={Yamusic} />
              <Route exact path="/kespa" component={Kespa} />
              <Route exact path="/tornadobet" component={Tornadobet} />
              <Route exact path="/rastenika" component={Rastenika} />
              <Route exact path="/sportcoupon" component={SportCoupon} />
              <Route exact path="/behance" component={Behance} />
              <Route exact path="/bookscriptor" component={Bks} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <Switch location={location}>
          <Route exact path="/info" component={Info} />
          <Route path="/" component={Work} />
          <Route component={NotFound} />
        </Switch>
        <Logos>
          <img src={habrlogo} alt="habrlogo" height="40px" />
          <img src={universallogo} alt="universallogo" />
          <img src={quantumlogo} alt="quantumlogo" />
          <img src={oglogo} alt="oglogo" />
          <img src={kespalogo} alt="kespalogo" />
          <img src={corwelllogo} alt="corwelllogo" />
        </Logos>
        <Footer>
          <div>
            {translations[lang].taifAlimov} - { new Date().getFullYear() }
            {/* <img height="24" width="auto" src={logo} alt="inzeppelin" /> */}
          </div>
        </Footer>
      </React.Fragment>
    );
  }
}

export const App = withRouter(AppComponent);

const Languages = styled.div`
  margin: 0 8px;
  color: #000;
  opacity: 0.5;
  text-decoration: none;
`;

const Language = styled.span`
  padding: 0 4px;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const Logos = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100px;
  margin-top: 20px;
  align-items: center;
  border-top: 1px solid #ddd;
  flex-wrap: wrap;
  > img {
    opacity: 0.2;
    max-height: 40px;
  }
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  height: 80px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
`;

// const Logo = styled.a`
//   height: 40px;
//   margin-right: 24px;
// `;

const NavItem = styled(NavLink)`
  margin: 0 8px;
  color: #000;
  opacity: 0.5;
  text-decoration: none;
  &:hover,
  &.active {
    opacity: 1;
  }
`;

const Footer = styled.footer`
  height: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.2);
  font-size: 14px;
  > img {
    margin-left: 8px;
  }
`;
