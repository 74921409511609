import React from 'react';
import Grid from 'styled-components-grid';
import { Title, Main } from '../components/Styled';
import styled from 'styled-components';
import info from '../assets/photos/info.jpeg';
import { SocialIcons } from '../components/SocialIcons';
import { translations } from '../translations';

export const Info = () => {
  const tr = translations[window.lang];
  return (
    <Main>
      <Grid>
        <Grid.Unit size={{ desktop: 0.495, mobile: 1 }}>
          <MyPhoto src={info} width="90%" height="auto" alt="myphoto" />
        </Grid.Unit>
        <Grid.Unit size={{ desktop: 0.495, mobile: 1 }}>
          <section>
            <Title>{tr.info}</Title>
            <p>{tr.myNameIs}</p>
            <PreParagraph>{tr.interested}</PreParagraph>
            <p>
              <strong>{tr.coreSkills}:</strong>
            </p>
            <Skill>
              <SkillDot />
              <SkillTitle>{tr.workingWithProduct}</SkillTitle>
              <SkillDetails>{tr.workingWithProductSkills}</SkillDetails>
            </Skill>
            <Skill>
              <SkillDot />
              <SkillTitle>{tr.design}</SkillTitle>
              <SkillDetails>{tr.designSkills}</SkillDetails>
            </Skill>
            <Skill>
              <SkillDot />
              <SkillTitle>{tr.markupStyles}</SkillTitle>
              <SkillDetails>{tr.markupStylesSkills}</SkillDetails>
            </Skill>
            <Skill>
              <SkillDot />
              <SkillTitle>{tr.coding}</SkillTitle>
              <SkillDetails>{tr.codingSkills}</SkillDetails>
            </Skill>
            <SocialIcons />
          </section>
        </Grid.Unit>
      </Grid>
    </Main>
  );
};


const SkillDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #908f90;
  float: left;
  margin-right: 12px;
  margin-bottom: 25px;
  margin-top: 7px;
  margin-left: 0;
`;

const SkillTitle = styled.p`
  margin: 0;
`;
const SkillDetails = styled.p`
  margin: 5px 0;
  font-size: 13px;
  color: #666;
`;

const Skill = styled.div``;

const PreParagraph = styled.p`
  white-space: break-spaces;
`;

const MyPhoto = styled.img`
  filter: grayscale(.6);
  border-radius: 6px;
`;