import React from 'react';
import { Modal } from '../components/Modal';
import {
  Tags,
  Tag,
  Background,
  Content,
  ModalTitle,
  Mockup,
} from '../components/Styled';
import Grid from 'styled-components-grid';
import rastenikamockup from '../assets/projects/rastenikamockup.jpg';
import { translations } from '../translations';

export class Rastenika extends React.Component {
  render() {
    const tr = translations[window.lang];
    return (
      <Modal>
        <Background>
          <Content>
            <ModalTitle>Rastenika</ModalTitle>
            <Tags>
              <Tag color="#5f5f5f">ui / ux</Tag>
              <Tag color="#ed6793">styling</Tag>
            </Tags>
            <Grid
              style={{
                display: 'flex',
                marginTop: '20px',
                justifyContent: 'space-between',
              }}
            >
              <Grid.Unit size={{ desktop: 0.6, mobile: 1 }}>
                <Mockup
                  src={rastenikamockup}
                  alt="rastenikamockup"
                  width="100%"
                />
              </Grid.Unit>
              <Grid.Unit size={{ desktop: 0.36, mobile: 1 }}>
                <p>
                  <strong>{tr.case}</strong>
                </p>
                <p>{tr.rastenikaCase}</p>
                <p>
                  <strong>{tr.role}</strong>
                </p>
                <p>UI/UX Designer, Product Designer, Frontend-developer</p>
                <p>
                  <strong>{tr.coreFeatures}</strong>
                </p>
                <ul>
                  <li>{tr.rastenikaFeat1}</li>
                  <li>{tr.rastenikaFeat2}</li>
                  <li>{tr.rastenikaFeat3}</li>
                  <li>{tr.rastenikaFeat4}</li>
                </ul>
              </Grid.Unit>
            </Grid>
          </Content>
        </Background>
      </Modal>
    );
  }
}
