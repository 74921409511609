import React from 'react';
import { Modal } from '../components/Modal';
import {
  Tag,
  Toggler,
  TogglerContainer,
  Mockup,
  FlexCenter,
  ModalTitle,
  FeatBlock,
  Overview,
  Tags,
} from '../components/Styled';
import { Tools } from '../components/Tools';
import yamusicbefore from '../assets/projects/yamusicbefore.jpg';
import yamusicafter from '../assets/projects/yamusicafter.jpg';
import yamusicback from '../assets/projects/yamusicback.jpg';
import yamusicui from '../assets/projects/yamusicui.png';
import yafeat1 from '../assets/projects/yafeat1.jpg';
import yafeat2 from '../assets/projects/yafeat2.jpg';
import yamobile from '../assets/projects/yamusicmobile.png';
import yafeat5 from '../assets/projects/yafeat5.jpg';
import yafeat12 from '../assets/projects/yafeat12.jpg';
import yaproto from '../assets/projects/yaproto.png';
import yalogo from '../assets/projects/yalogo.png';
import { translations } from '../translations';
import Grid from 'styled-components-grid';
import styled from 'styled-components';

export class Yamusic extends React.Component {
  state = {
    newVersion: true,
    comments: false,
  };

  handleChangeVersion = () => {
    this.setState(prevState => {
      return { newVersion: !prevState.newVersion };
    });
  };

  handleChangeComments = () => {
    this.setState(prevState => {
      return { comments: !prevState.comments };
    });
  };

  render() {
    const { newVersion } = this.state;
    const tr = translations[window.lang];
    return (
      <Modal background="#1e354d">
        <Background />
        <Background2 />
        <Content>
          <FlexCenter>
            <Logo src={yalogo} alt="yalogo" width="100px" />
            <ModalTitle>Yandex.Music</ModalTitle>
            <Tags>
              <Tag color="#359252">concept</Tag>
              <Tag color="#5f5f5f">ui / ux</Tag>
            </Tags>
            <TogglerContainer>
              {tr.before}
              <Toggler
                color="#009688"
                type="checkbox"
                id="toggler"
                defaultChecked={newVersion}
                onChange={this.handleChangeVersion}
              />
              <label htmlFor="toggler" />
              {tr.after}
            </TogglerContainer>
            {newVersion ? (
              <Mockup src={yamusicafter} alt="yamusicafter" width="100%" />
            ) : (
              <Mockup src={yamusicbefore} alt="yamusicbefore" width="100%" />
            )}
          </FlexCenter>

          <FeatBlock>
            <Overview>
              <SubHead>
                <strong>{tr.case}</strong>
              </SubHead>
              <p>{tr.yamusicCase}</p>
            </Overview>
          </FeatBlock>
        </Content>
        <FullWidth>
          <img src={yamusicui} alt="yamusicui" width="100%" />
        </FullWidth>
        <Content>
          <FlexCenter>
            <p>{tr.yamusicFeat2Info}</p>
            <img src={yaproto} alt="yaproto" width="100%" />
            <ModalTitle offsetTop>{tr.coreFeatures}</ModalTitle>
          </FlexCenter>
          <FeatBlock>
            <Grid style={{ width: '100%' }}>
              <Grid.Unit size={{ desktop: 0.3, mobile: 1 }}>
                <SubHead>{tr.yamusicFeat1}</SubHead>
                <p>{tr.yamusicFeat1Info}</p>
              </Grid.Unit>
              <Grid.Unit size={{ desktop: 0.7, mobile: 1 }}>
                <Mockup
                  borderRadius="5px"
                  src={yafeat1}
                  alt="yafeat1"
                  width="60%"
                />
                <Mockup
                  borderRadius="5px"
                  src={yafeat12}
                  alt="yafeat12"
                  width="60%"
                />
              </Grid.Unit>
            </Grid>
          </FeatBlock>

          <FeatBlock>
            <Grid style={{ width: '100%' }}>
              <Grid.Unit size={{ desktop: 0.3, mobile: 1 }}>
                <SubHead>{tr.yamusicFeat3}</SubHead>
                <p>{tr.yamusicFeat3Info}</p>
              </Grid.Unit>
              <Grid.Unit size={{ desktop: 0.7, mobile: 1 }}>
                <Mockup
                  borderRadius="5px"
                  src={yafeat2}
                  alt="yafeat2"
                  width="60%"
                />
              </Grid.Unit>
            </Grid>
          </FeatBlock>
          <FeatBlock>
            <Grid style={{ width: '100%' }}>
              <Grid.Unit size={{ desktop: 0.3, mobile: 1 }}>
                <SubHead>{tr.yamusicFeat5}</SubHead>
                <p>{tr.yamusicFeat5Info}</p>
              </Grid.Unit>
              <Grid.Unit size={{ desktop: 0.7, mobile: 1 }}>
                <Mockup
                  borderRadius="5px"
                  src={yafeat5}
                  alt="yafeat5"
                  width="60%"
                />
              </Grid.Unit>
            </Grid>
          </FeatBlock>
          <FeatBlock>
            <Grid style={{ width: '100%' }}>
              <Grid.Unit size={{ desktop: 0.45, mobile: 1 }}>
                <Mockup src={yamobile} alt="yafeat5" width="50%" />
              </Grid.Unit>
              <Grid.Unit size={{ desktop: 0.55, mobile: 1 }}>
                <Overview>
                  <SubHead>{tr.mobileReady}</SubHead>
                  <p>{tr.yamusicMobileInfo}</p>
                </Overview>
              </Grid.Unit>
            </Grid>
          </FeatBlock>
        </Content>
        <Tools toolsList={['AffinityDesigner', 'AdobePhotoshop']} />
      </Modal>
    );
  }
}

const Logo = styled.img`
  margin-top: 100px;
`;

const Background = styled.div`
  background: url('${yamusicback}') no-repeat center center; 
  position: absolute;
  background-size: cover;
  width: 100vw;
  right: 0;
  top: 0;
  height: 120vh;
  z-index: -1;
  opacity: .4;
`;

const Background2 = styled.div`
  background: linear-gradient(transparent, #1e354d);
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  top: 0;
  height: 120vh;
`;

const Content = styled.div`
  max-width: 980px;
  margin: 20px auto;
  padding: 0 8px;
  color: #fff;
`;

const SubHead = styled.p`
  font-size: 22px;
  color: #ffcb00;
  margin-bottom: 0;
  font-weight: bold;
  align-self: flex-start;
`;

const FullWidth = styled.div`
  width: 100%;
  padding: 0 0;
`;
