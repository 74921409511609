import React from 'react';
import { Modal } from '../components/Modal';
import {
  Tags,
  Tag,
  Content,
  ModalTitle,
  FlexCenter,
  FeatBlock,
} from '../components/Styled';
import { Tools } from '../components/Tools';
import Grid from 'styled-components-grid';
import kespaback from '../assets/projects/kespaback.jpg';
import kespaapp from '../assets/projects/kespaapp.png';
import kespaflow from '../assets/projects/kespaflow.png';
import kespaprogress from '../assets/projects/kespaprogress.png';
import kespashape from '../assets/projects/kespashape.png';
import styled from 'styled-components';
import { translations } from '../translations';

export class Kespa extends React.Component {
  render() {
    const tr = translations[window.lang];
    return (
      <Modal background="#eefcff">
        <Background />
        <Background2 />
        <Content>
          <Grid style={{ width: '100%', marginTop: '60px' }}>
            <Grid.Unit
              size={{ desktop: 0.6, mobile: 1 }}
              style={{ textAlign: 'center' }}
            >
              <img src={kespaapp} alt="kespaapp" width="80%" />
            </Grid.Unit>
            <Grid.Unit size={{ desktop: 0.3, mobile: 1 }}>
              <ModalTitle color="#000" offsetTop leftAligned>
                Kespa App
              </ModalTitle>
              <Tags leftAligned>
                <Tag color="#5f5f5f">ui / ux</Tag>
              </Tags>

              <p>{tr.kespaCase}</p>
              <p>{tr.kespaCase2}</p>
            </Grid.Unit>
          </Grid>
          <FeatBlock>
            <FlexCenter>
              <ModalTitle leftAligned color="#000">
                {tr.gettingShape}
              </ModalTitle>
              <ShortText>{tr.gettingShapeInfo}</ShortText>
              <img
                src={kespashape}
                alt="kespashape"
                width="100%"
                style={{ maxWidth: '980px' }}
              />
            </FlexCenter>
          </FeatBlock>

          <FeatBlock>
            <FlexCenter>
              <ModalTitle leftAligned color="#000">
                {tr.interactions}
              </ModalTitle>
              <ShortText>{tr.interactionsInfo}</ShortText>
              <img
                src={kespaflow}
                alt="kespaflow"
                width="80%"
                style={{ maxWidth: '500px' }}
              />
            </FlexCenter>
          </FeatBlock>

          <FeatBlock>
            <FlexCenter>
              <ModalTitle leftAligned color="#000">
                {tr.progress}
              </ModalTitle>
              <ShortText>{tr.progressInfo}</ShortText>
              <img src={kespaprogress} alt="kespaprogress" height="140px" />
            </FlexCenter>
          </FeatBlock>
          {/* <p>
            <strong>{tr.coreFeatures}</strong>
          </p>
          <ul>
            <li>{tr.kespaFeat1}</li>
            <li>{tr.kespaFeat2}</li>
            <li>{tr.kespaFeat3}</li>
            <li>{tr.kespaFeat4}</li>
          </ul> */}
        </Content>
        <Tools light toolsList={['Framer', 'Figma']} />
      </Modal>
    );
  }
}

const Background = styled.div`
  background: url('${kespaback}') no-repeat center center; 
  position: absolute;
  background-size: cover;
  width: 100vw;
  right: 0;
  top: 0;
  height: 120vh;
  z-index: -1;
  opacity: .9;
`;

const Background2 = styled.div`
  background: linear-gradient(transparent, #eefcff);
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  top: 0;
  height: 120vh;
`;

const ShortText = styled.p`
  max-width: 500px;
  margin-bottom: 40px;
`;
