export const translations = {
  en: {
    work: 'work',
    info: 'info',
    resume: 'resume',
    myNameIs:
      "My name is Taif. I'm a Produt Designer & Fullstack Developer.",
    interested:
      "Fullstack with 10+ years of web development expertise. Strong UI/UX skills and 5+ years of team leadership experience. Committed to creating exceptional user experiences through visually appealing interfaces. \n\nI'm really interested in the intersection of design, coding an business. I like to make ux better going deep into the essence of problems, finding best solutions and build products that perfectly cover users needs.",
    coreSkills: 'Core skills',
    workingWithProduct: 'Working with product',
    workingWithProductSkills: 'User stories, roles and journeys, Wireframing',
    design: 'Design',
    designSkills: 'Figma, Framer, Photoshop, Affinity Designer, ',
    markupStyles: 'Markup & Styling',
    markupStylesSkills: 'Cross-platform HTML(JSX), CSS, SASS, BEM, CSS-In-JS',
    coding: 'Development',
    codingSkills: 'JS, React, Vue, Node JS, Postgres, Git',
    email: 'Write me email',
    github: 'See GitHub Profile',
    linkedIn: 'Get in touch in LinkedIn',
    telegram: 'Send telegram message',
    hey: "Hey, I'm Taif",
    taifAlimov: 'Taif Alimov',
    before: 'Before',
    after: 'After',
    logo: 'Logo',
    concept: 'Core concept',
    colors: 'Color & Font',
    guardsManRed: 'Guardsman Red',
    ghostWhite: 'Ghost White',
    blueWhale: 'Blue Whale',
    midnight: 'Midnight',
    uiElements: 'UI Elements',
    case: 'Case',
    outcomes: 'Outcomes',
    leftMenu: 'Left Menu',
    role: 'Role',
    coreFeatures: 'Core features',
    behanceCase:
      'Sign Up form redesign. The main goal here was to make form more attractive and modern.',
    bksCase: 'Bookscriptor platform allows authors to publish their books online and sell it. Product ecosystem includes marketplace, reader, editor, publishing tool and social media elements. Whole product was built from scratch - from user roles and stories to micro-interactions and animations.',
    kespaCase: 'Kespa app allows user to learn English using NLU technologies.',
    kespaCase2: 'The core concept of app is quite simple. User clicks on top blue area and listens text in mother language. Then clicks on white area and translate it in English. NLU algorithm checks if it is correct and allows to proceed to the next task.',
    sportCouponCase: 'Sport Coupon is a list of events with primary outcomes filtered by some different rules. The main goal was to create all-purpose interface that allows user to build any coupon.',
    rastenikaCase: 'Rastenika is a marketplace for plant growers. The main goal was to create obvious UX-flow for users, who are not familiar much with complex web-interfaces.',
    tornadobetCase: 'Tornadobet is a website based on flexible React betting-platfrom. The main goal was to create dark-themed website which would stand out from others.',
    yamusicCase: 'Artist page redesign. The main goal here was to use all the space effectively and provide most important information at first viewport. Also important part of the concept is to create own visual representation for every UI element.',
    im:
      "I'm a Product Designer & Fullstack Developer with skills and experiences that allow me to bring ideas from concept to production",
    more: 'and over 30 more projects',
    progress: 'Progress',
    promotions: 'Promotions',
    progressInfo: 'Lesson progress is shown as circle loader around mnemonic picture which illustrates current language construction.',
    mobileReady: 'Mobile Friendly',
    interactions: 'Interactions',
    interactionsInfo: 'Interactive areas have different states: playing, recording, processing, failure and success.',
    gettingShape: 'Finding shape',
    gettingShapeInfo: 'App flow is based on two main actions: listening and recording. UI concept was developed from simple buttons to dividing the screen into two interactive areas, which allows to use the app.',
    yamusicMobileInfo: 'UI blocks can be easily adapted for mobile screens with the same functionallity. Moreover, mobile version keeps the same content on the first screen in portrait orientation (iPhone X size).',
    behanceFeat1: 'Page has become more attractive and easy-to-use;',
    behanceFeat2: 'Form composition has become more accurate and structured;',
    behanceFeat3: 'Country field has got auto-fill by geo data;',
    behanceFeat4:
      'Date of birth field has got masked input which allows to reduce clicks;',
    behanceFeat5:
      'Action button has become larger according to its importance;',
    bksFeat1: 'Over 500 use-cases were worked out with over 200 mockups;',
    bksFeat2: 'Easy-to-use publishing process with auto-parsing;',
    bksFeat3: 'Well-structured catalogue of books with genres, tags and recomendation engine;',
    bksFeat4: 'Precise cross-platform reader with bookmarks, easy navigation through the book and smart reading progress keeper;',
    bksFeat5: 'Powerfull user profile with own library, collections and communication tools;',
    kespaFeat1: 'Gamification and achivements system;',
    kespaFeat2: 'Adaptive learning curve;',
    kespaFeat3: 'Context-based help;',
    kespaFeat4:  'Simple and stylish UI which is coincident to Kespa brandbook.',   
    rastenikaFeat1: 'Bright simple and fresh design for 35+ audience;',
    rastenikaFeat2: 'Homepage allows to get the understanding of project ideas from the first look;',
    rastenikaFeat3: 'Smart searching engine and comprehensive product attributes;',
    rastenikaFeat4: 'Multiple user roles and journey-maps cover over 20 popular use-cases.',
    sportCouponFeat1: 'Sports, dates, competitions and markets filters allow to build popular coupons like "Weekend" or "Odds-on" as well as completely personalized coupon.',
    sportCouponFeat2: 'Live and prematch events are combined into one list;',
    sportCouponFeat3: 'All filters are grouped and logically structured: competitions by regions and countries, sports and markets by types;',
    sportCouponFeat4: 'Quick filters options are based on users geo-data, previous searches and bets history;',
    sportCouponFeat5: 'Favourites feature allows to create a list of interested events from different ux-cycles of events exploring;',
    tornadobetFeat1: 'Modern and bright color scheme which is associated with passion, excitement and mysteriousness.',
    tornadobetFeat2: 'Logo shape comes from simplified forms of T-letter, tornado, cup and emblem.',
    tornadobetFeat3: 'Outcomes buttons have 6 different states.',
    tornadobetFeat4: 'Two-level menu allows to navigate trhough sports and regions. Since the whole app is SPA, central column updates instantly according to selected menu items.',
    tornadobetFeat5: 'Promo blocks can be used for advertisment or promotions for specific events. In this case user can make a bet directly from the promo block.',
    yamusicFeat1: 'Artist / User',
    yamusicFeat1Info: 'Conceptually artist is a special case of user. Some kind of super-user, who produce the content. This is why artist should be looking more saturate visually.',
    yamusicFeat2: 'External things go to the right',
    yamusicFeat2Info: 'This page in fact is a dashboard for artist. User wants to see comprehensive information about the artist quickly. So the idea is to keep on first screen as much useful information as possible.', 
    yamusicFeat3: 'Tracks and albums',
    yamusicFeat3Info: 'Albums and tracks are independent units now and can be used anywhere without context. For example, as a widget in external website.',
    yamusicFeat4: 'Genres have got their own visual identity;',
    yamusicFeat5: 'Concetrs',
    yamusicFeat5Info: 'Concerts were added to premium place, because artist page is the best place to make cross-sale with Yandex.Afisha. In most cases one concert matched by geo-data is enough.',
    yamusicFeat6: 'Right column has become wider. It allows to place larger and more complex advertisment content.',
},
  ru: {
    work: 'работа',
    info: 'инфо',
    resume: 'резюме',
    myNameIs:
      'Меня зовут Таиф. Занимаюсь продуктовым дизайном и веб-разработкой.',
    interested:
      'Фуллстек с более чем 10-летним опытом веб-разработки. Сильные навыки UI/UX и более 5 лет опыта руководства командой. Стремление создавать исключительный пользовательский опыт с помощью визуально привлекательных интерфейсов.\n\nМне нравится работать на пересечении дизайна, разработки и бизнеса. Нравится погружаться глубоко в суть проблем, находить лучшие их решения и создавать продукты, которые идеально решают задачи пользователей.',
    coreSkills: 'Ключевые навыки',
    workingWithProduct: 'Работа с продуктом',
    workingWithProductSkills:
      'Пользовательские истории, роли и карты, Прототипирование',
    design: 'Дизайн',
    designSkills: 'Figma, Framer, Photoshop, Affinity Designer',
    markupStyles: 'Верстка и стили',
    markupStylesSkills:
      'Кросс-платформенный HTML(JSX), CSS, SASS, BEM, CSS-In-JS',
    coding: 'Разработка',
    codingSkills: 'JS, Vue, React, Node JS, Postgres, Git',
    email: 'Написать письмо',
    github: 'Посмотреть GitHub',
    linkedIn: 'Профиль в LinkedIn',
    telegram: 'Сообщение в Телеграмм',
    hey: 'Привет! Меня зовут Таиф',
    taifAlimov: 'Таиф Алимов',
    before: 'До',
    case: 'Кейс',
    logo: 'Лого',
    leftMenu: 'Левое меню',
    concept: 'Концепция',
    colors: 'Цвет и шрифт',
    guardsManRed: 'Гвардейский красный',
    ghostWhite: 'Туманно-белый',
    blueWhale: 'Синий кит',
    midnight: 'Полуночный',
    uiElements: 'Элементы интерфейса',
    progress: 'Прогресс',
    progressInfo: 'Прогресс урока отображается круговым лоадером вокруг мнемонического изображения текущей языковой конструкции.',
    role: 'Роль',
    outcomes: 'Исходы',
    coreFeatures: 'Ключевые моменты',
    interactions: 'Взаимодействия',
    interactionsInfo: 'Интерактивные области имеют различные состояния: индикации проигрывания, записи, обработки и результатов выполнения задания.',
    mobileReady: 'Mobile Friendly',
    gettingShape: 'Поиск формы',
    gettingShapeInfo: 'Основная механика использования приложения состоит из двух действий: прослушать и записать. Концепция развивалась от примитивных кнопок до разделения экрана на две большие функциональные области, необходимые для взаимодействия с прилоежением.',
    yamusicMobileInfo: 'Блоки легко перестраиваются под мобильные экраны, не теряя функциональности. Более того, мобильная версия вмещает в себя не меньше полезного контента на первом экране в портретной ориентации (для iPhone X)',
    behanceCase:
      'Редизайн формы регистрации. Основная цель – сделать форму более привлекательной и современной.',
    bksCase: 'Платформа Bookscriptor позволяет авторам издавать свои книги онлайн и продавать их. Экосистема продукта включает в себя маркетплэйс, ридер, редактор, инструменты для публикации и элементы соц. сетей. Весь продукт был создан с нуля - от пользовательских историй и ролей до микро-взаимодействий и анимаций.',
    kespaCase: 'Приложение Kespa позволяет практиковать английский используя NLU-технологии.',
    kespaCase2: 'Основная концепция приложения очень простая. Пользователь нажимает на голубую область в верхней части и прослушивает текст на родном языке. После чего нажимет на белую облатсь снизу и произносит перевед на английском. Алгоритм NLU проверяет корректность перевода и если все хорошо пользователь переходит к следующему заданию.',
    rastenikaCase: 'Rastenika - это маркетплэйс для растениеводов. Основной задачей было сделать очевидный интерфейс для аудитории, которая плохо знакома с устройством сложных интерфейсов',
    sportCouponCase: 'Купон - это список спортивных событий с основными исходами, которые сгруппированы по каким-либо правилам. Основная цель этого проекта - создать универсальный интерфейс, которые позволит пользователю быстро и просто создать любой купон.',
    tornadobetCase: 'Tornadobet построен на React беттинг-платформе. Основной целью было создание сайта в темных тонах, который бы выделялся на фоне конкурентов.',
    yamusicCase: 'Редизайн страницы исполнителя. Основная цель - использовать пространство эффективнее и расположить наиболее важную информацию на первый экран. Кроме того, важной частью задачи было создать свое визуальное представление для каждого элемента интерфейса.',
    after: 'После',
    promotions: 'Промо блоки',
    im:
      'Я занимаюсь продуктовым дизайном и веб-разработкой. Опыт и знания позволяют разрабатывать проекты от идеи до продакшна',
    more: 'и еще более 30 проектов',
    behanceFeat1: 'Страница стала более привлекательной и простой;',
    behanceFeat2:
      'Композиционно форма стала аккуратнее, появилась наглядная структура;',
    behanceFeat3:
      'Поле "Страна" заполняется автоматически на основе гео-данных пользователя;',
    behanceFeat4:
      'Поле "Дата рождения" заполняется через маску, что позволяет сократить количество кликов при выборе дат;',
    behanceFeat5: 'Кнопка регистрации стала больше и заметнее.',
    bksFeat1: 'Более 500 пользовательских историй и более 200 макетов;',
    bksFeat2: 'Простой и удобный процесс публикации с авто-парсингом рукописей;',
    bksFeat3: 'Структурированный каталог книг с жанрами, тэгами и механизмом рекомендаций;',
    bksFeat4: 'Удобный кросс-платформенный ридер с закладками, простой навигацией по книге и умной системой трекинга прогресса чтения;',
    bksFeat5: 'Функциональный личный кабинет пользователя с библиотекой, коллекциями и инструментами общения.',
    kespaFeat1: 'Геймификация и система достижений;',
    kespaFeat2: 'Адаптивная кривая обучения;',
    kespaFeat3: 'Компонент помощи основан на контексте использования;',
    kespaFeat4: 'Простой и стильный UI в соответствии с брендбуком Kespa',     
    rastenikaFeat1: 'Яркий простой и свежий дизайн для аудитории 35+;',
    rastenikaFeat2: 'Главная страница позволяет с первого взгляда понять идею проекта;',
    rastenikaFeat3: 'Умный механизм поиска по каталогу и максимально подробные характеристики продуктов;',
    rastenikaFeat4: 'Несколько пользовательских ролей и journey-maps покрывают более 20 наиболее популярных сценариев использования',
    sportCouponFeat1: 'Фильтры по спорту, датам и соревнованию позволяют быстро создать как популярные купоны ("Выходные", "Фавориты"), так и полностью персонализированные купоны;',
    sportCouponFeat2: 'Прематчевые и лайв события объединены в один список;',
    sportCouponFeat3: 'Все фильтры имеют группировку и логическую структуру: соревнования по регионам и странам, виды спорта и маркеты по типам;',
    sportCouponFeat4: 'Быстрые опции для фильтров основаны на гео-данных пользователя, истории поиска и истории ставок;',
    sportCouponFeat5: 'Функция "Избранное" позволяет создать список интересных событий за несколько циклов фильтрации и поиска событий.',
    tornadobetFeat1: 'Современная и яркая цветовая схема, которая ассоциируется с азартом, энергией и таинственностью;',
    tornadobetFeat2: 'Логотип представляет собой упрощенную форму торнадо, буквы Т, кубка и эмблемы.',
    tornadobetFeat3: 'Кнопки исходов имеют 6 различных состояний.',
    tornadobetFeat4: 'Двухуровневое меню позволяет осуществлять навигацию по спортам и регионам. Приложение представляет собой SPA, так что центральная колонка обновляется мгновенно в соответствии с выбранными пунктами меню.',
    tornadobetFeat5: 'Промо-блоки могут использоваться для рекламы или для продвижения конкретных событий. В этом случае, сделать ставку на матч можно прямо из промо-блока.',
    yamusicFeat1: 'Пользователь / Исполнитель',
    yamusicFeat1Info: 'Концептуально исполнитель – это частный случай пользователя. Некий супер-пользователь, который производит контент. Поэтому визуально исполнитель должен выглдядеть насыщеннее.',
    yamusicFeat2: 'Внешние функции справа',
    yamusicFeat2Info: 'Эта страница фактически дэшбоард артиста. Пользователь хочет получить исчерпыющую информацию по артисту как можно скорее. Поэтому было решено уместить максимум полезной информации в первый экран.',
    yamusicFeat3: 'Треки и альбомы',
    yamusicFeat3Info: 'Альбомы и треки стали независимыми, это позволяет их использовать отдельно, без контекста. Например, как виджет в стороннем ресурсе.',
    yamusicFeat4: 'Жанры получили визуальное представление;',
    yamusicFeat5: 'Конерты',
    yamusicFeat5Info: 'Концерты появились на премиальной позиции. Страница артиста – лучшее место, чтобы делать кросс-сэйл c Яндекс.Афиша. В большинстве случаев одного анонса по геолокации пользователя достаточно.',
    yamusicFeat6: 'Правая колонка стала шире - это позволяет разместить больше рекламы и сделать ее более сложной и интересной.',
},
};
