import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import { close } from 'react-icons-kit/fa/close';

export class Modal extends React.Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].className = 'no-scroll';
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].className = '';
  }
  render() {
    const backgroundColor = this.props.background || '#F0EFF0';
    const color = this.props.color || 'inherit';
    return (
      <div
        style={{ backgroundColor: backgroundColor, color: color }}
        className="overlay"
      >
        <CloseButton color={backgroundColor} exact to="/">
          <Icon size={32} icon={close} />
        </CloseButton>
        {this.props.children}
      </div>
    );
  }
}

const CloseButton = styled(NavLink)`
  position: fixed;
  top: 16px;
  z-index: 9;
  right: 16px;
  opacity: 0.3;
  color: ${({ color }) => color};
  filter: invert(100%);
  &:hover {
    opacity: 1;
  }
`;
