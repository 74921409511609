import React from 'react';
import { Icon } from 'react-icons-kit';
import styled from 'styled-components';
import { github } from 'react-icons-kit/fa/github';
import { envelope } from 'react-icons-kit/fa/envelope';
import { telegram } from 'react-icons-kit/fa/telegram';
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare';
import { translations } from '../translations';

export const SocialIcons = () => {
  const tr = translations[window.lang];
  return (
    <StyledSocialIcons>
      <a
        rel="noopener noreferrer"
        href="mailto:inzeppelin@gmail.com"
        title={tr.email}
      >
        <Social icon={envelope} onHover="#c71610" />
      </a>
      <a
        rel="noopener noreferrer"
        href="https://github.com/Inzeppelin"
        target="_blank"
        title={tr.github}
      >
        <Social icon={github} onHover="#333" />
      </a>
      <a
        rel="noopener noreferrer"
        href="https://www.linkedin.com/in/alimovt/"
        target="_blank"
        title={tr.linkedIn}
      >
        <Social icon={linkedinSquare} onHover="#0077b5" />
      </a>
      <a
        rel="noopener noreferrer"
        href="https://t.me/inzeppelin"
        target="_blank"
        title={tr.telegram}
      >
        <Social icon={telegram} onHover="#0088cc" />
      </a>
    </StyledSocialIcons>
  );
};
const StyledSocialIcons = styled.p`
  margin-top: 40px;
  > a {
    color: #000;
  }
`;

const Social = styled(Icon)`
  width: 32px;
  height: 32px;
  opacity: 0.4;
  margin-right: 16px;
  cursor: pointer;
  > svg {
    width: 100%;
    height: auto;
  }
  &:hover {
    opacity: 1;
    > svg path {
      color: ${({ onHover }) => onHover};
    }
  }
`;
