import React from 'react';
import { Modal } from '../components/Modal';
import {
  Tags,
  Tag,
  Background,
  Content,
  ModalTitle,
  Mockup,
} from '../components/Styled';
import Grid from 'styled-components-grid';
import bksmockup from '../assets/projects/bksmockup.jpg';
import { translations } from '../translations';

export class Bks extends React.Component {
  render() {
    const tr = translations[window.lang];
    return (
      <Modal>
        <Background>
          <Content>
            <ModalTitle>Bookscriptor</ModalTitle>
            <Tags>
              <Tag color="#5f5f5f">ui / ux</Tag>
            </Tags>
            <Grid
              style={{
                display: 'flex',
                marginTop: '20px',
                justifyContent: 'space-between',
              }}
            >
              <Grid.Unit size={{ desktop: 0.6, mobile: 1 }}>
                <Mockup src={bksmockup} alt="bksmockup" width="100%" />
              </Grid.Unit>
              <Grid.Unit size={{ desktop: 0.36, mobile: 1 }}>
                <p>
                  <strong>{tr.case}</strong>
                </p>
                <p>{tr.bksCase}</p>
                <p>
                  <strong>{tr.role}</strong>
                </p>
                <p>Product Designer</p>
                <p>
                  <strong>{tr.coreFeatures}</strong>
                </p>
                <ul>
                  <li>{tr.bksFeat1}</li>
                  <li>{tr.bksFeat2}</li>
                  <li>{tr.bksFeat3}</li>
                  <li>{tr.bksFeat4}</li>
                  <li>{tr.bksFeat5}</li>
                </ul>
              </Grid.Unit>
            </Grid>
          </Content>
        </Background>
      </Modal>
    );
  }
}
