import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Grid from 'styled-components-grid';
import {
  TitleSection,
  Title,
  SubTitle,
  Main,
  Tags,
  Tag,
} from '../components/Styled';
// import geo from './geo.svg';
import ya from '../assets/projects/ya.jpg';
import tornado from '../assets/projects/tornado.jpg';
import behance from '../assets/projects/behance.jpg';
import betting from '../assets/projects/betting.jpg';
import bks from '../assets/projects/bks.jpg';
import kespa from '../assets/projects/kespa.jpg';
import avatar from '../assets/photos/avatar.jpg';
import rastenika from '../assets/projects/rastenika.jpg';
import { translations } from '../translations';

export const Work = () => {
  const tr = translations[window.lang];
  return (
    <Main>
      <TitleSection>
        <Grid>
          <Grid.Unit
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            size={{ desktop: 0.2, mobile: 1 }}
          >
            <Avatar src={avatar} alt="avatar" />
          </Grid.Unit>
          <Grid.Unit size={{ desktop: 0.8, mobile: 1 }}>
            <Title>{tr.hey}</Title>
            <SubTitle>{tr.im}</SubTitle>
            {/* <p>
            <img
              style={{ marginRight: '8px' }}
              src={geo}
              alt="geo"
              width="16"
              height="auto"
            />
            Moscow, Russia
          </p> */}
          </Grid.Unit>
        </Grid>
      </TitleSection>
      <Grid halign="justify">
        <StyledGridUnit
          style={{ backgroundImage: `url(${ya})` }}
          size={{ desktop: 0.59, mobile: 1 }}
        >
          <NavLink to="/yamusic">
            <Cover>
              <ImageTitle>Yandex.Music</ImageTitle>
              <Tags>
                <Tag color="#359252">concept</Tag>
                <Tag color="#5f5f5f">ui / ux</Tag>
              </Tags>
            </Cover>
          </NavLink>
        </StyledGridUnit>
        <StyledGridUnit
          style={{ backgroundImage: `url(${kespa})` }}
          size={{ desktop: 0.4, mobile: 1 }}
        >
          <NavLink to="/kespa">
            <Cover>
              <ImageTitle>Kespa App</ImageTitle>
              <Tags>
                <Tag color="#5f5f5f">ui / ux</Tag>
              </Tags>
            </Cover>
          </NavLink>
        </StyledGridUnit>
        <StyledGridUnit
          style={{ backgroundImage: `url(${tornado})` }}
          size={{ desktop: 0.495, mobile: 1 }}
        >
          <NavLink to="/tornadobet">
            <Cover>
              <ImageTitle>Tornado Bet</ImageTitle>
              <Tags>
                <Tag color="#5f5f5f">ui / ux</Tag>
                <Tag color="#ed6793">styling</Tag>
                <Tag color="#0abeda">react</Tag>
              </Tags>
            </Cover>
          </NavLink>
        </StyledGridUnit>
        <StyledGridUnit
          style={{ backgroundImage: `url(${rastenika})` }}
          size={{ desktop: 0.495, mobile: 1 }}
        >
          <NavLink to="/rastenika">
            <Cover>
              <ImageTitle>Rastenika</ImageTitle>
              <Tags>
                <Tag color="#5f5f5f">ui / ux</Tag>
                <Tag color="#ed6793">styling</Tag>
              </Tags>
            </Cover>
          </NavLink>
        </StyledGridUnit>
        <StyledGridUnit
          style={{ backgroundImage: `url(${betting})` }}
          size={{ desktop: 0.245, mobile: 1 }}
        >
          <NavLink to="/sportcoupon">
            <Cover>
              <ImageTitle>Sport Coupon</ImageTitle>
              <Tags>
                <Tag color="#359252">concept</Tag>
                <Tag color="#5f5f5f">ui / ux</Tag>
              </Tags>
            </Cover>
          </NavLink>
        </StyledGridUnit>
        <StyledGridUnit
          style={{ backgroundImage: `url(${bks})` }}
          size={{ desktop: 0.245, mobile: 1 }}
        >
          <NavLink to="/bookscriptor">
            <Cover>
              <ImageTitle>Bookscriptor</ImageTitle>
              <Tags>
                <Tag color="#5f5f5f">ui / ux</Tag>
              </Tags>
            </Cover>
          </NavLink>
        </StyledGridUnit>
        <StyledGridUnit
          style={{ backgroundImage: `url(${behance})` }}
          size={{ desktop: 0.245, mobile: 1 }}
        >
          <NavLink to="/behance">
            <Cover>
              <ImageTitle>Behance Sign Up</ImageTitle>
              <Tags>
                <Tag color="#359252">concept</Tag>
                <Tag color="#5f5f5f">ui / ux</Tag>
              </Tags>
            </Cover>
          </NavLink>
        </StyledGridUnit>
        <StyledGridUnitLast size={{ desktop: 0.245, mobile: 1 }}>
          {tr.more}
        </StyledGridUnitLast>
      </Grid>
    </Main>
  );
};

const Avatar = styled.img`
  border-radius: 50%;
  width: 70%;
  height: auto;
`;

const StyledGridUnitLast = styled(Grid.Unit)`
  background: #e1e1e1;
  margin-bottom: 9.8px;
  border-radius: 8px;
  overflow: hidden;
  padding: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
`;

const StyledGridUnit = styled(Grid.Unit)`
  ${props =>
    props.size.desktop > 0.3
      ? 'min-height: 320px;'
      : 'min-height: 160px;'} border-radius: 8px;
  background-color: #2f2f28;
  margin-bottom: 9.8px;
  background-size: cover;
  background-position: center center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  &:before {
    content: '';
    background: linear-gradient(0, #000000, #383838);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  &:hover {
    &:before {
      opacity: 0.8;
    }
    div {
      opacity: 1;
    }
  }
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImageTitle = styled.h2`
  font-weight: 600;
  color: #fff;
  margin: 0;
  z-index: 1;
`;
