import React from 'react';
import styled from 'styled-components';
import AffinityDesigner from '../assets/icons/affinity.png';
import AdobePhotoshop from '../assets/icons/photoshop.png';
import Confluence from '../assets/icons/confluence.png';
import Figma from '../assets/icons/figma.png';
import Framer from '../assets/icons/framer.png';
import GutHub from '../assets/icons/github.png';
import ReactJS from '../assets/icons/react.png';
import Redux from '../assets/icons/redux.png';
import StyledComponents from '../assets/icons/sc.png';

const toolSrc = {
  AffinityDesigner,
  ReactJS,
  AdobePhotoshop,
  Confluence,
  Figma,
  Framer,
  GutHub,
  StyledComponents,
  Redux,
};

export const Tools = ({ toolsList, light }) => {
  return (
    <ToolsContainer light={light}>
      <StyledToolsIcons>
        {toolsList.map(tool => {
          return <Tool title={tool} alt={tool} src={toolSrc[tool]} />;
        })}
      </StyledToolsIcons>
      <ToolsTitle light={light}>Thanks for watching!</ToolsTitle>
    </ToolsContainer>
  );
};

const ToolsContainer = styled.div`
    background: ${({light}) => light ? 'rgba(255,255,255,.3)' : 'rgba(0,0,0,.3)'};
    padding: 20px 0;
    margin-top: 40px;
`;

const ToolsTitle = styled.h2`
  text-align: center;
  font-size: 40px;
  color: ${({light}) => light ? '#000' : '#fff'};
`;

const StyledToolsIcons = styled.p`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tool = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 16px;
`;
